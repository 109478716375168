import api from "@/api.js";

const state = {
  accessToken: localStorage.getItem("accessToken"),
  refreshToken: localStorage.getItem("refreshToken"),
  userEmail: localStorage.getItem("userEmail"),
};

const getters = {
  isAuthenticated: (state) => !!state.accessToken,
  getAccessToken: (state) => state.accessToken,
  getRefreshToken: (state) => state.refreshToken,
  getUserEmail: (state) => state.userEmail,
};

const actions = {
  async refresh({ commit }, refreshToken) {
    const { data } = await api.post("token/refresh/", {
      refresh: refreshToken,
    });
    commit("setAccessToken", data.access);
  },

  async login({ commit }, loginData) {
    const { data } = await api.post("token/", loginData);
    data.email = loginData.email;
    commit("setData", data);
  },

  async logout({ commit }) {
    commit("logout");
  },
};

const mutations = {
  setData(state, payload) {
    state.accessToken = payload.access;
    state.refreshToken = payload.refresh;
    state.userEmail = payload.email;
    localStorage.setItem("accessToken", payload.access);
    localStorage.setItem("refreshToken", payload.refresh);
    localStorage.setItem("userEmail", payload.email);
  },
  setAccessToken(state, accessToken) {
    state.accessToken = accessToken;
    localStorage.setItem("accessToken", accessToken);
  },
  logout(state) {
    state.accessToken = null;
    state.refreshToken = null;
    state.userEmail = null;
    localStorage.clear();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
