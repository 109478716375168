import Vue from "vue";
import App from "./App.vue";
import VueGeolocation from "vue-browser-geolocation";
import * as VueGoogleMaps from "vue2-google-maps";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// CSS
require("@/assets/css/app-icons.css?v0.0.1");
require("@/assets/css/app-base.css?v0.0.3");
require("@/assets/css/custom.css?v0.0.3");
// Template: https://www.bootstrapdash.com/demo/star-admin-vue/preview/#/dashboard
// . CSS

Vue.config.productionTip = false;

Vue.use(VueGeolocation);
Vue.use(VueGoogleMaps, {
  load: {
    libraries: ["places", "geometry"],
    key: process.env.VUE_APP_GOOGLE_API_KEY,
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
