import axios from "axios";
import store from "@/store/index";
// import router from "@/router/index";

let api = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    let token = store.state.auth.accessToken;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

const interceptor = api.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error) => {
    if (error.response.status !== 401) {
      return Promise.reject(error);
    }

    api.interceptors.response.eject(interceptor);

    try {
      let refreshToken = store.getters["auth/getRefreshToken"];
      await store.dispatch("auth/refresh", refreshToken);
      error.config.headers["Authorization"] =
        "Bearer " + store.state.auth.accessToken;
      return await api.request(error.config);
    } catch {
      await store.dispatch("auth/logout");
      // router.push({ name: "login" });
    }
  }
);

export default api;
