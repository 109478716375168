import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store/index";
// import api from "@/api.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: () => import("../views/Home.vue"),
    children: [
      {
        path: "",
        name: "listBuildings",
        meta: { title: 'Lista de edificios' },
        component: () => import("../views/ListBuildings.vue"),
      },
      {
        path: "comparador_de_edificios",
        name: "compareBuildings",
        meta: { title: 'Comparador de edificios' },
        component: () => import("../views/CompareBuildings.vue"),
      },
      {
        path: "nuevo_edificio",
        name: "createBuilding",
        meta: { title: 'Añadir edificio' },
        component: () => import("../views/CreateBuilding.vue"),
        beforeEnter: async (to, from, next) => {
          let isAuthenticated = store.getters["auth/isAuthenticated"];
          if (isAuthenticated) {
            next();
          } else {
            next({
              name: "login",
            });
          }
        },
      },
      {
        path: "edificio/:id",
        component: () => import("../views/EditBuilding.vue"),
        children: [
          {
            path: "caracteristicas",
            name: "building",
            component: () => import("../components/CreateBuildingForm.vue"),
          },
          {
            path: "instalacion",
            name: "energy",
            component: () => import("../components/BuildingEnergyForm.vue"),
          },
          {
            path: "calificacion",
            name: "score",
            component: () => import("../components/BuildingScoreForm.vue"),
          },
          {
            path: "mejoras",
            name: "improvements",
            component: () =>
              import("../components/BuildingImprovementsForm.vue"),
          },
        ],
      },
      {
        path: "detalles/:id",
        name: "details",
        meta: { title: 'Detalles' },
        component: () => import("../views/DetailsBuilding.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    meta: { title: 'Iniciar sesión' },
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/acerca_de_support",
    name: "about",
    meta: { title: 'Acerda de SUPPORT' },
    component: () => import("../views/About.vue"),
  },
  {
    path: "/funcionalidades_del_observatorio",
    name: "functionalities",
    meta: { title: 'Funcionalidades del Observatorio' },
    component: () => import("../views/Functionalities.vue"),
  },
  {
    path: "/condiciones_de_uso",
    name: "conditions",
    meta: { title: 'Condiciones de uso' },
    component: () => import("../views/Conditions.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
